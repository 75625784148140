<template>
  <el-dialog :visible.sync="isOpen" width="80%" center custom-class="cs-dialog">
    <template slot="title">
      <div class="fs-20 font-bold uppercase">
        {{ $t("Chọn thành viên nhận điểm") }}
      </div>
    </template>
    <b-overlay :show="isLoading" rounded="sm">
      <div>
        <div class="flex flex-row gap-2">
          <div
            class="flex flex-col md:flex-row gap-2 md:items-center"
            style="flex: 1"
          >
            <label>{{ $t("Tìm kiếm") }}</label>
            <el-input
              clearable
              class="flex-1"
              v-model="searchValue"
              placeholder="Nhập tên thành viên"
              @input="handleDebounce"
            ></el-input>
          </div>
          <div
            class="flex flex-col md:flex-row gap-2 md:items-center"
            style="flex: 1"
          >
            <label>
              {{ $t("Sử dụng chương trình nạp điểm cho thành viên mới") }}
            </label>
            <b-form-select
              v-model="is_first_time"
              :options="firstTimeProgramOptions"
              @change="getOrgMembers()"
            ></b-form-select>
          </div>
        </div>

        <div class="mt-1 mb-2 flex flex-row gap-2 items-center justify-between">
          <div class>{{ `Đã chọn: ${totalSelected || 0} thành viên` }}</div>
          <el-button
            @click="handleSelectAll"
            type="primary"
            class="btn"
            size="small"
            >{{ $t(isSelectAll ? "Bỏ chọn tất cả" : "Chọn tất cả") }}</el-button
          >
        </div>
        <div>
          <el-table
            ref="multipleTable"
            :data="listDoctors"
            style="width: 100%"
            border
            row-key="id"
            empty-text="Không có dữ liệu"
            @selection-change="handleUpdateSelectedProducts"
            @select="handleSelectRow"
          >
            <el-table-column
              reserve-selection
              type="selection"
            ></el-table-column>
            <el-table-column :label="$t('Tên thành viên')">
              <template slot-scope="scope">{{ getName(scope.row) }}</template>
            </el-table-column>
            <!-- <el-table-column :label="$t('Email')">
              <template slot-scope="scope">{{ scope.row.email }}</template>
            </el-table-column>-->
            <el-table-column :label="$t('Gói')">
              <template slot-scope="scope">
                {{ getPackage(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('Nạp điểm cho thành viên mới')">
              <template slot-scope="scope">
                <b-badge
                  :variant="
                    scope.row.user && scope.row.user.is_first_time_top_up === 2
                      ? 'success'
                      : 'primary'
                  "
                >
                  {{
                    $t(
                      scope.row.user &&
                        scope.row.user.is_first_time_top_up === 2
                        ? "Đã áp dụng"
                        : "Chưa áp dụng"
                    )
                  }}
                </b-badge>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <Pagination
          class="mt-1"
          :meta="pagination.meta"
          :perPage="pagination.pageSize"
          :currentPage="pagination.currentPage"
          :totals="pagination.totalItems"
          @setCurrentPage="handleSetCurrentPage"
          @setPerPage="handleSetPerPage"
        />-->
        <!-- <AppPagination :paginationProps="pagination" @onChangePagination="getOrgMembers" /> -->
      </div>
    </b-overlay>

    <span slot="footer" class="dialog-footer">
      <div class="flex gap-2 items-center">
        <el-button :disabled="isLoading" class="flex-1" @click="handleClose">{{
          $t("Đóng")
        }}</el-button>
        <el-button
          :disabled="isLoading"
          class="flex-1 btn"
          type="primary"
          @click="handleSubmit"
          >{{ $t("Xác nhận") }}</el-button
        >
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { debounce } from "lodash";
// import AppPagination from '../../../components/Common/AppPagination.vue'
import "element-ui/lib/theme-chalk/index.css";
import { Button, Table, TableColumn, Dialog, Input, Select } from "element-ui";
import appUtils from "@/utils/appUtils";
// import Pagination from '@/layouts/components/Pagination.vue'

export default {
  name: "ModalSelectDoctors",
  components: {
    // AppPagination,
    "el-button": Button,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-dialog": Dialog,
    "el-input": Input,
    // 'el-select': Select,
    // Pagination
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isSelectAll: false,
      searchValue: "",
      subOrgs: [],
      workspaces: [],
      listDoctors: [],
      pagination: {
        currentPage: 1,
        pageSize: 200,
        totalItems: 0,
        meta: {},
      },
      // mdtUtils,
      totalSelected: 0,
      selectedDoctors: [],
      allMembers: [],
      is_first_time: null,
      firstTimeProgramOptions: [
        { value: null, text: "Tất cả" },
        {
          value: 1,
          text: "Chưa được áp dụng",
        },
        {
          value: 2,
          text: "Đã được áp dụng",
        },
      ],
    };
  },

  methods: {
    handleClose() {
      this.isOpen = false;
    },
    handleSubmit() {
      this.$emit("onSelectedDoctors", this.selectedDoctors);
      this.isOpen = false;
    },
    handleOpen(data) {
      this.isOpen = true;
      this.selectedDoctors = [...data] || [];
      this.getOrgMembers();
    },
    handleUpdateSelectedProducts(data) {
      if (this.isSelectAll) {
        // const newData = [...this.allMembers];
        // this.selectedDoctors = this.allMembers;
        // this.totalSelected = this.allMembers?.length || 0;
      } else {
        this.selectedDoctors = data;
        this.totalSelected = data?.length || 0;
      }
      // this.$emit("onSelectedDoctors", data);
    },
    async getOrgMembers(data = {}, isSelectAll) {
      const userData = appUtils.getLocalUser();

      if (!userData.supplierInfo?.org_id) return;
      try {
        const params = {
          org_id: userData.supplierInfo.org_id,
          // status: 1,
          page_size: data.pageSize || this.pagination.pageSize,
          page_num: data.currentPage || this.pagination.currentPage,
          keyword: this.searchValue,
          sort_by: "created_at",
          order: "desc",
        };

        if (this.is_first_time) {
          params.is_first_time = this.is_first_time;
        }

        if (isSelectAll) {
          params.page_num = 1;
          params.page_size = this.pagination.totalItems;
          params.keyword = "";
        }
        this.isLoading = true;
        const response = await this.$rf
          .getRequest("CommonRequest")
          .getListPatient(params);

        if (isSelectAll) {
          return response.data?.data || [];
        }
        this.listDoctors = response.data?.data || [];
        this.pagination = {
          currentPage: response.data?.page?.page_num || 1,
          pageSize: response.data?.page?.page_size || this.pagination.pageSize,
          totalItems: response.data?.page?.total || 0,
          meta: {
            from:
              this.pagination.pageSize * this.pagination.currentPage -
              this.pagination.pageSize,
            to: this.pagination.pageSize * this.pagination.currentPage,
            of: this.pagination.totalItems,
          },
        };
        if (this.isSelectAll) {
          this.$refs.multipleTable.clearSelection();
          this.listDoctors.forEach((item) => {
            const index = this.allMembers.findIndex(
              (selectedItem) => selectedItem?.id === item?.id
            );
            const isSelected = index !== -1;
            if (isSelected) {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        }
        this.totalItems = response.data?.meta?.total || 0;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleDebounce: debounce(function () {
      this.getOrgMembers({ currentPage: 1 });
    }, 500),

    async handleSelectAll() {
      if (this.isSelectAll) {
        this.isSelectAll = false;
        this.$refs.multipleTable.clearSelection();
        this.allMembers = [];
        this.selectedDoctors = [];
        this.totalSelected = 0;
      } else {
        this.isSelectAll = true;
        const allMembers = (await this.getOrgMembers({}, true)) || [];
        this.allMembers = allMembers;
        this.selectedDoctors = allMembers;
        this.totalSelected = allMembers?.length;
        this.listDoctors.forEach((item) => {
          const index = this.allMembers.findIndex(
            (selectedItem) => selectedItem?.id === item?.id
          );
          const isSelected = index !== -1;
          if (isSelected) {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        });
      }
    },
    handleSelectRow(selection, row) {
      if (this.isSelectAll) {
        const rowIndex = this.allMembers?.findIndex(
          (item) => item?.id === row?.id
        );
        if (rowIndex !== -1) {
          this.allMembers.splice(rowIndex, 1);
        } else {
          this.allMembers.push(row);
        }
        this.totalSelected = this.allMembers?.length;
        this.selectedDoctors = this.allMembers;
      }
      this.isSelectAll = false;
    },
    handleClearSelectAll() {
      this.isSelectAll = false;
      this.$refs.multipleTable.clearSelection();
      this.allMembers = [];
      this.selectedDoctors = [];
      this.totalSelected = 0;
    },
    handleSetCurrentPage(currentPage) {
      const params = {
        ...this.pagination,
        currentPage,
      };

      this.getOrgMembers(params);
    },
    handleSetPerPage(pageSize) {
      const params = {
        ...this.pagination,
        pageSize,
      };

      this.getOrgMembers(params);
    },
    getName(person) {
      return person?.name || person?.user?.name || person?.user?.username;
    },
    getPackage(person) {
      return (
        person.user?.user_member?.member_package?.name || "Thành viên thường"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-dialog {
    border-radius: 8px;
  }

  .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: 1px solid #dcdfe6 !important;
    background-color: white !important;
  }

  .el-input-group__append {
    background-color: white !important;
  }

  .cs-disabled {
    .el-input__inner {
      background-color: rgba(228, 228, 228, 0.823) !important;
      color: #373737 !important;
    }

    .el-input-group__append {
      background-color: rgba(228, 228, 228, 0.823) !important;
    }
  }
}
.btn {
  border-color: unset;
  background: #20419b;
}
</style>