<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-card>
        <validation-observer ref="DepositValidation">
          <b-form>
            <validation-provider
              #default="{ errors }"
              :name="$t('Chương trình nạp điểm')"
              rules="required"
            >
              <b-form-group label="Chọn chương trình nạp điểm" class="required">
                <v-select
                  class="bg-white"
                  v-model="form.program_id"
                  label="name"
                  value="id"
                  append-to-body
                  :clearSearchOnSelect="false"
                  :filterable="false"
                  :searchable="true"
                  :id="`select-filter`"
                  :reduce="(option) => option.id"
                  :options="depositPrograms"
                  @search="handleDebounce"
                >
                  <template slot="no-options">{{
                    $t("lbl_no_option")
                  }}</template>
                  <template #option="data">
                    <span>{{ `${data.name} - ${data.point} Điểm` }}</span>
                  </template>
                  <template #selected-option="data">
                    <span>{{ `${data.name} - ${data.point} Điểm` }}</span>
                  </template>
                </v-select>
                <!-- <b-form-select v-model="form.program_id">
                <b-form-select-option
                  v-for="(item, index) in depositPrograms"
                  :key="index"
                  :value="item.id"
                  >{{
                    `${item.name} - ${item.point} Điểm`
                  }}</b-form-select-option
                >
                </b-form-select>-->
                <small v-if="errors.length" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>

            <div class="mt-1">
              <div class="mt-2">
                <div class="flex justify-between items-end">
                  <h5 class="mb-0 flex" style="align-items: end">
                    {{ $t("Danh sách Thành viên") }}
                  </h5>
                  <b-button
                    variant="primary"
                    size="md"
                    @click="handleAddPerson"
                    >{{ $t("Thêm thành viên") }}</b-button
                  >
                </div>
                <b-table
                  striped
                  hover
                  responsive
                  :items="selectedMembers"
                  :fields="fieldsTable"
                  class="mt-1 mb-3"
                  show-empty
                  center
                >
                  <template #cell(package)="scope">
                    {{ getPackage(scope.item) }}
                  </template>
                  <template #empty>
                    <div class="text-center">
                      {{ $t("Chưa chọn thành viên") }}
                    </div>
                  </template>
                  <template #cell(index)="data">
                    <div class>{{ data.index + 1 }}</div>
                  </template>
                </b-table>
              </div>
            </div>
          </b-form>
        </validation-observer>
        <div class="flex justify-content-end gap-3 mt-2">
          <b-button
            variant="outline-primary"
            @click="$router.push({ name: 'deposit' })"
            >{{ $t("Huỷ") }}</b-button
          >
          <b-button
            @click="handleDepositPoint"
            variant="primary"
            class="w-fit"
            >{{ $t("Nạp điểm") }}</b-button
          >
        </div>
      </b-card>
      <ModalSelectDoctors
        ref="ModalSelectDoctors"
        @onSelectedDoctors="handleChangeSelectedDoctors"
        :selectedDoctorsProps="selectedMembers"
      />
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ModalSelectDoctors from "../ModalSelectDoctors.vue";
import appUtils from "@/utils/appUtils";
import { HODO_CREDIT_TYPE } from "@/utils/constant";
import vSelect from "vue-select";
import { debounce } from "lodash";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ModalDeposit",
  components: {
    ValidationProvider,
    ValidationObserver,
    ModalSelectDoctors,
    vSelect,
  },
  data() {
    return {
      depositPrograms: [],
      required,
      form: {
        program_id: null,
        moneyValue: null,
        memberPoints: null,
      },
      realData: {
        moneyValue: null,
        memberPoints: null,
      },
      configData: {},
      selectedMembers: [],
      fieldsTable: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "name",
          label: "Tên thành viên",
        },
        // {
        //   key: "email",
        //   label: "Email",
        // },
        {
          key: "package",
          label: "Gói",
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    pointsValue() {
      const points = this.realData.moneyValue / (this.configData?.rate || 0);

      return {
        round: this.roundToFourDecimals(points),
        value: points,
      };
    },
  },
  created() {
    this.handleGetDeposit();
  },
  methods: {
    async handleDepositPoint() {
      this.$refs.DepositValidation.validate().then(async (success) => {
        if (success) {
          const userData = appUtils.getLocalUser();

          if (!userData.supplierInfo?.org_id) return;
          try {
            this.isLoading = true;
            const params = {
              program_id: this.form.program_id,
              org_id: userData.supplierInfo?.org_id,
              user_ids: this.selectedMembers?.map((item) => item.user_id),
            };

            const response = await this.$rf
              .getRequest("CommonRequest")
              .depositPoint(params);

            if (response.status === 200) {
              this.$router.replace({
                name: "deposit",
              });

              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: this.$t(`Nạp điểm thành công`),
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          } catch (error) {
            console.log(error);

            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: this.$t(`Nạp điểm thất bại`),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    handleAddPerson() {
      this.$refs.ModalSelectDoctors.handleOpen(this.selectedMembers);
    },
    handleSelectedMembers(data) {
      this.selectedMembers = data || [];
    },

    handleChangeSelectedDoctors(data) {
      this.selectedMembers = data;
    },
    async handleGetDeposit(searchValue) {
      const userData = appUtils.getLocalUser();

      if (!userData.supplierInfo?.org_id) return;
      try {
        const params = {
          page_num: 1,
          page_size: 10000,
          org_id: userData.supplierInfo?.org_id,
          type: HODO_CREDIT_TYPE.deposit,
          sort_by: "created_at",
          order: "desc",
          keyword: searchValue,
        };

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getListPointProgram(params);

        this.depositPrograms = (response.data?.data || []).map((item) => ({
          ...item,
          value: item.id,
          text: item.name,
        }));
      } catch (error) {
        console.log(error);
      }
    },
    handleDebounce: debounce(function (value) {
      this.handleGetDeposit(value);
    }, 300),
    getPackage(person) {
      return (
        person.user?.user_member?.member_package?.name || "Thành viên thường"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>